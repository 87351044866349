.release-notes-container {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px; }

.accordion-container {
  background: white;
  user-select: none; }

.accordion-main {
  padding: 16px;
  font-size: 16px;
  border: 1px solid #f1f1f1; }

.accordion-main:hover {
  background-color: #dbeafe62; }

.accordion-body {
  height: auto;
  max-height: 0px;
  box-sizing: border-box;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  background-color: #f9fafb; }

.accordion-body > div {
  padding: 24px; }

.accordion-body > div > ul + h1, .accordion-body > div > ol + h1 {
  padding-top: 32px; }

.accordion-body ol > ol, .accordion-body ol > ul, .accordion-body ul > ol, .accordion-body ul > ul {
  padding-left: 16px; }

.accordion-body ol {
  list-style-type: decimal;
  list-style-position: inside; }

.accordion-body ol > li {
  font-weight: 500;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 20px; }

.accordion-body ul > li {
  font-size: 14px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 20px; }

.accordion-body ul {
  list-style-type: disc;
  list-style-position: inside; }

.accordion-body h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  padding-bottom: 8px; }
