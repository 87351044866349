.compress-view-container {
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }

.top-buttons {
  display: flex;
  justify-content: end;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-right: 22rem; }

.top-buttons div {
  margin-left: 2rem; }

.bulk-compress-button {
  margin-left: 5rem; }

.text-center {
  text-align: center; }

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

.custom-button-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.custom-button-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439; }

.auto_compression_modal {
  width: 500px;
  padding-left: 15px;
  padding-right: 15px;
  color: white; }

.btn-zips {
  background: #877325;
  color: #ffffff;
  margin: 10px;
  padding: 4px 12px;
  border-radius: 5px; }

.modal-main-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1050;
  padding: 2rem;
  overflow-y: auto;
  text-align: center;
  padding-top: 3rem;
  background-color: rgba(0, 0, 0, 0.8); }

.custom-compress-sub-container {
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem; }

.color-methods {
  display: flex;
  align-items: center;
  column-gap: 5px; }

.color-methods * {
  margin: 0 !important; }

.color-methods input:checked ~ label {
  color: black !important; }

.dithering-methods {
  display: flex;
  flex-direction: column;
  row-gap: 4px; }

.dithering-methods > div {
  display: flex;
  align-items: center;
  column-gap: 5px; }

.dithering-methods input:checked ~ label {
  color: black !important; }

.dithering-methods label,
.dithering-methods input {
  margin: 0 !important; }

.custom-compress_preview {
  width: 80%; }

.self-align-start {
  align-self: flex-start; }

.swal2-container {
  z-index: 20000 !important; }

.custom-compress_buttons {
  display: flex;
  justify-content: end;
  padding-right: 3rem;
  column-gap: 2rem; }

.equal {
  width: 100%; }

.margin-right {
  margin-right: 3rem; }

.custom-compress-view_configuration-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  background: white; }

.custom-compress-view_image-container {
  width: 130%; }

.form-group label {
  font-weight: 600 !important; }

.form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }

.form-label {
  min-width: 120px;
  text-align: start; }

.range-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px; }

.range-input-number {
  width: 50px;
  margin: 0 !important;
  text-align: right;
  border-radius: 5px;
  background: #f0f0f0;
  border: none !important; }

.range-input {
  -webkit-appearance: none;
  background: #f0f0f0;
  height: 8px;
  border-radius: 50px; }

.range-input::-webkit-slider-thumb {
  background: red; }

.range-input::-moz-range-thumb {
  background: red; }

.input-number {
  width: 60px; }

.custom_progress {
  color: white; }

.horizontal-line {
  border-bottom: 1px solid #c7c7c7b8;
  margin-bottom: 8px;
  margin-top: 8px; }

.form-header {
  text-align: start;
  font-weight: bold;
  margin-bottom: 2rem; }

.form-input {
  display: flex;
  align-items: center; }

.justify-items-left {
  display: flex;
  justify-content: flex-end; }

.modal-hidden {
  display: hidden; }

.self-align-start {
  align-self: flex-start; }

.modal-sub-container {
  display: inline-block;
  background: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 5px; }

.compress-view-bulk-progress-bar {
  font-weight: bold; }

.bulk-compress-progress {
  width: 500px;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white; }

.bulk-compress__view-settings {
  display: flex;
  flex-direction: column;
  align-items: center; }

.bulk-compress__view-settings__footer {
  display: flex;
  justify-content: center;
  column-gap: 5px; }

.style__tab-content {
  padding: 25px;
  width: 500px; }

.mode-header {
  background: #f1e9e9;
  display: flex;
  align-items: center;
  height: 7rem;
  padding-left: 2rem;
  padding-right: 2rem; }

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px; }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
