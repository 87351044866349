/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
.setting-page .stepOne-form {
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px; }
  .setting-page .stepOne-form .form-group a {
    display: inline-block;
    margin-top: 5px;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer; }

.setting-page .nav-tabs {
  border-bottom: 0 none; }
  .setting-page .nav-tabs li {
    width: 50%; }
    .setting-page .nav-tabs li a {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      text-align: center; }
      .setting-page .nav-tabs li a:hover {
        background-color: transparent; }
    .setting-page .nav-tabs li.active > a {
      background-color: transparent;
      border: 1px solid #ddd !important; }
      .setting-page .nav-tabs li.active > a:focus {
        background-color: transparent;
        outline: 0; }
      .setting-page .nav-tabs li.active > a:hover {
        background-color: transparent; }
    .setting-page .nav-tabs li.active > .selectedSetting {
      background: #807325; }
  .setting-page .nav-tabs li.setting-modal-btn a {
    color: #ffffff;
    background-color: #5bc0de;
    border-color: #46b8da;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px; }
    .setting-page .nav-tabs li.setting-modal-btn a:hover {
      border: 1px solid #46b8da; }
