@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
body {
  font: 100% "Open Sans", sans-serif;
  color: #333333;
  background-color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans", sans-serif; }

h1 {
  font-size: 22px;
  display: inline-block;
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  font-weight: 600; }

a:selection,
a:-moz-selection,
a::-webkit-selection, .btn::-webkit-selection {
  background: transparent; }

.btn::selection, a::selection {
  background: transparent; }

a::selection, .btn::selection {
  background: transparent; }

a::-moz-selection, .btn::-moz-selection {
  background: transparent; }

.banner-style .btn::selection {
  background: #0F74BD; }

/***For Other Browsers ***/
a::-o-selection, .btn::-o-selection {
  background: transparent; }

a:foucs {
  outline: 0 none; }

:focus {
  outline: none; }

a:selection {
  background: transparent; }

.btn::selection {
  background: transparent; }

.btn.active.focus,
.btn.active:focus,
.btn.focus, .btn:active.focus,
.btn:active:focus,
.btn:focus,
.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  outline: none; }

input[type="number"].custom_input_number {
  -moz-appearance: textfield; }

input[type="number"]:hover,
input[type="number"]:focus {
  -webkit-appearance: number-input;
  -moz-appearance: number-input;
  appearance: number-input; }

input[type="number"] {
  position: relative;
  margin: 0 0 1rem;
  border: 1px solid #BBB;
  border-color: #BBB #ECECEC #ECECEC #BBB;
  padding: .2rem; }

/* Spin Buttons modified */
input[type="number"].mod::-webkit-outer-spin-button,
input[type="number"].mod::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
  width: 1em;
  border-left: 1px solid #BBB;
  opacity: .5;
  /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; }

input[type="number"].mod::-webkit-inner-spin-button:hover,
input[type="number"].mod::-webkit-inner-spin-button:active {
  opacity: .8; }

/* Override browser form filling */
input:-webkit-autofill {
  background: black;
  color: red; }

.btn {
  -webkit-user-select: text;
  -moz-user-select: all;
  -ms-user-select: text;
  user-select: all; }

.pd-top20 {
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px; }

.pd-bottom15 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-left: 0px; }

/*Buttons*/
.btn {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-top: 7px;
  padding-right: 12px;
  padding-bottom: 7px;
  padding-left: 12px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .btn:hover {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }

.btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px; }
  .btn-info:hover {
    background-color: #46b8da;
    border: 1px solid #46b8da; }
  .btn-info:active {
    background-color: #46b8da;
    border: 1px solid #46b8da; }
  .btn-info:focus {
    background-color: #46b8da;
    border: 1px solid #46b8da; }
  .btn-info:active:focus {
    background-color: #46b8da;
    border: 1px solid #46b8da; }
  .btn-info:disabled {
    background-color: #46b8da;
    border: 1px solid #46b8da; }

.btn-default {
  background-color: #e1e5ec;
  border-color: #e1e5ec;
  color: #666666;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px; }
  .btn-default:hover {
    background-color: #d1d7e2;
    border: 1px solid #e1e5ec; }
  .btn-default:active {
    background-color: #d1d7e2;
    border: 1px solid #e1e5ec; }
  .btn-default:focus {
    background-color: #e1e5ec;
    border: 1px solid #e1e5ec; }
  .btn-default:active:focus {
    background-color: #d1d7e2;
    border: 1px solid #4fbfe0; }
  .btn-default:disabled {
    background-color: #d1d7e2;
    border: 1px solid #4fbfe0; }

.btn-danger {
  background-color: #f56954;
  border-color: #f56954;
  color: #ffffff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px; }
  .btn-danger:hover {
    background-color: #f4614a;
    border: 1px solid #f56954; }
  .btn-danger:active {
    background-color: #f4614a;
    border: 1px solid #f56954; }
  .btn-danger:focus {
    background-color: #f4614a;
    border: 1px solid #f56954; }
  .btn-danger:active:focus {
    background-color: #f4614a;
    border: 1px solid #f56954; }
  .btn-danger:disabled {
    background-color: #f4614a;
    border: 1px solid #f56954; }

.btn-success {
  background-color: #36c6d3;
  border-color: #2bb8c4;
  color: #ffffff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px; }
  .btn-success:hover {
    background-color: #2bb8c4;
    border: 1px solid #2bb8c4; }
  .btn-success:active {
    background-color: #2bb8c4;
    border: 1px solid #2bb8c4; }
  .btn-success:focus {
    background-color: #2bb8c4;
    border: 1px solid #2bb8c4; }
  .btn-success:disabled, .btn-success:disabled .btn-success:hover {
    background-color: #2bb8c4 !important;
    border: 1px solid #36c6d3; }
  .btn-success:active:focus {
    background-color: #26a4b0;
    border: 1px solid #2bb8c4; }

.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover {
  background-color: #26a4b0;
  border: 1px solid #2bb8c4; }

.btn-black {
  background-color: #373e4a; }

.btn-primary {
  background-color: #4fbfe0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  border: #4fbfe0 solid 1px; }
  .btn-primary:hover {
    background-color: #4fbfe0;
    border: 1px solid #4fbfe0; }
  .btn-primary:active {
    background-color: #4fbfe0;
    border: 1px solid #4fbfe0; }
  .btn-primary:focus {
    background-color: #4fbfe0;
    border: 1px solid #4fbfe0; }
  .btn-primary:active:focus {
    background-color: #4fbfe0;
    border: 1px solid #4fbfe0; }
  .btn-primary:disabled {
    background-color: #4fbfe0;
    border: 1px solid #4fbfe0; }

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #373e4a;
  -webkit-text-fill-color: #333333;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color #ffffff ease-in-out 0s; }

.pagination {
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px; }
  .pagination ul {
    display: inline-block;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
    .pagination ul li {
      display: inline; }
      .pagination ul li a, .pagination ul li span {
        float: left;
        padding-top: 12px;
        padding-right: 15px;
        padding-bottom: 12px;
        padding-left: 15px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
        margin-left: 0px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        border-radius: 4px;
        line-height: 20px;
        font-weight: 700;
        text-decoration: none;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-left-width: 0;
        cursor: pointer;
        font-size: 14px;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
        .pagination ul li a:hover, .pagination ul li span:hover {
          background-color: #dfb604;
          color: #ffffff;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; }
    .pagination ul:first-child a {
      border-left: none; }
  .pagination ul.disabled span, .pagination ul.disabled a {
    color: #333333;
    background-color: #fffda3;
    cursor: default;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .pagination li.active a, .pagination li.active span {
    background-color: #dfb604;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }

input.form-control {
  font-family: "Open Sans", sans-serif;
  font-size: 16px; }

body .bradcrumb-top {
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: -15px;
  margin-bottom: 0px;
  margin-left: -15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background-color: #F1F1F1; }
  body .bradcrumb-top h1 {
    float: left !important;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 15px; }
  body .bradcrumb-top .project-title {
    float: left;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #373e4a; }
    @media (min-width: 1024px) {
      body .bradcrumb-top .project-title {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 5px;
        margin-left: 5px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      body .bradcrumb-top .project-title {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        margin-top: 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        margin-left: 10px; } }
  body .bradcrumb-top .bradcrumb-btn.pull-right {
    margin-right: 15px; }
    @media (min-width: 768px) and (max-width: 1023px) {
      body .bradcrumb-top .bradcrumb-btn.pull-right {
        float: left !important; } }
  body .bradcrumb-top ul.list-inline {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
    body .bradcrumb-top ul.list-inline > li {
      float: left;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 5px;
      margin-left: 0px; }
      @media (max-width: 991px) {
        body .bradcrumb-top ul.list-inline > li {
          margin-top: 0px;
          margin-right: 5px;
          margin-bottom: 5px;
          margin-left: 0px;
          padding-top: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          padding-left: 0px; } }
      body .bradcrumb-top ul.list-inline > li a.btn-primary {
        color: #ffffff;
        text-transform: uppercase;
        background-color: #4fbfe0;
        border: 1px solid #4fbfe0;
        font-size: 14px;
        font-weight: 600;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0; }
        body .bradcrumb-top ul.list-inline > li a.btn-primary:hover {
          background-color: #4eb5d4;
          color: #ffffff;
          border: 1px solid #4eb5d4;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        @media (max-width: 991px) {
          body .bradcrumb-top ul.list-inline > li a.btn-primary {
            padding-top: 6px;
            padding-right: 6px;
            padding-bottom: 6px;
            padding-left: 6px;
            font-size: 13px; } }
      body .bradcrumb-top ul.list-inline > li:last-child {
        padding-right: 0px; }
      body .bradcrumb-top ul.list-inline > li.current a.btn-primary {
        background-color: #4eb5d4;
        color: #ffffff;
        font-size: 14px; }
        @media (max-width: 991px) {
          body .bradcrumb-top ul.list-inline > li.current a.btn-primary {
            font-size: 13px; } }
  body .bradcrumb-top .btn-primary {
    color: #ffffff;
    text-transform: uppercase;
    background-color: #4fbfe0;
    border: 1px solid #4fbfe0;
    font-size: 14px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    body .bradcrumb-top .btn-primary:hover {
      background-color: #4eb5d4;
      color: #ffffff;
      border: 1px solid #4eb5d4;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    @media (min-width: 768px) and (max-width: 1023px) {
      body .bradcrumb-top .btn-primary {
        padding-top: 6px;
        padding-right: 6px;
        padding-bottom: 6px;
        padding-left: 6px;
        font-size: 13px; } }

.stepOne {
  max-width: 500px;
  margin: 40px auto;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px; }
  .stepOne h3 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    color: #ffffff; }
  .stepOne label {
    font-weight: 500;
    font: 100% "Raleway", sans-serif;
    color: #fff;
    font-size: 14px; }

.stepTwo {
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px; }
  .stepTwo h3 {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: #ffffff; }
  .stepTwo h4 {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: #ffffff; }
  .stepTwo .stepTwo-content {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px; }

.stepOne, .stepTwo {
  background-color: #14171f;
  background-image: -webkit-gradient(left top, left bottom, color-stop(0%, #14171f), color-stop(100%, #2b303a));
  background-image: -webkit-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -moz-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -ms-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -o-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: linear-gradient(to bottom, #14171f 0%, #2b303a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr='#14171f', end-colourStr='#2b303a',GradientType=0 ); }

th, td {
  text-align: left;
  font-weight: 400;
  color: #303641;
  font-size: 14px; }

.color-picker-wrapper .input-group .color-picker-input-swatch {
  font-family: 'Open Sans', sans-serif; }

.modal-dialog {
  margin: 10% auto;
  min-width: 450px; }
  .modal-dialog .modal-header {
    background: #303642;
    color: #FFFFFF;
    border-radius: 3px 3px 0px 0px; }
    .modal-dialog .modal-header .close {
      background: none;
      font-size: 30px;
      padding-top: 0px;
      padding-right: 2px;
      padding-bottom: 0px;
      padding-left: 2px;
      line-height: 22px;
      color: #ffffff;
      opacity: 1; }
      .modal-dialog .modal-header .close:hover {
        color: #ffffff; }
  .modal-dialog .modal-text {
    width: 250px;
    margin: 0 auto; }
    .modal-dialog .modal-text input[type=text] {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 0px; }
  .modal-dialog .modal-body textarea {
    height: 80px;
    width: 100%;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    resize: none; }
  .modal-dialog .footer-modal {
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    text-align: right; }

.modal-sm .modal-header {
  background: #303642;
  color: #FFFFFF;
  border-radius: 3px 3px 0px 0px; }

.file {
  position: relative;
  text-align: center;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: none;
  width: 300px;
  margin: 0 auto; }
  .file label {
    background: #36c6d3;
    padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 8px;
    padding-left: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .file input {
    position: absolute;
    display: inline-block;
    top: 19px;
    height: 40px;
    opacity: .01;
    cursor: pointer;
    width: 120px; }
  .file span {
    word-wrap: break-word; }
  .file .media-container {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px; }

.file input:hover + label,
.file input:focus + label {
  background: #36c6d3;
  color: #ffffff; }

.gif-image {
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  text-align: center; }
  .gif-image ul {
    list-style: none;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px; }
    .gif-image ul li {
      float: left;
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      display: inline-block;
      width: 140px;
      height: 150px; }
  .gif-image .row {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px; }

.add-section input[type=number]::-webkit-inner-spin-button,
.add-section input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

.preview-heading {
  color: #676767; }

.bannersize-detail {
  background-color: #F1F1F1;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-top: 5px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-left: 0px; }
  .bannersize-detail ul {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
    .bannersize-detail ul li {
      font-size: 16px;
      color: #373e4a;
      font-weight: 700; }
      .bannersize-detail ul li span {
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
        margin-left: 5px; }

.jquery-div {
  border: 5px solid grey;
  position: absolute; }

.create-template-banner {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none; }

.remove-project {
  float: right; }

.bootstrap-dialog-footer .btn {
  margin: 0px 5px; }

.bootstrap-dialog.type-primary .modal-header {
  background-color: #2b303a; }

.showOptions {
  display: block;
  opacity: 1; }

#toast-container .toast {
  margin: 0 !important; }

#toast-container > div {
  padding: 0 !important; }

.custom_select {
  position: relative; }
  .custom_select select {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    background-color: #ffffff;
    cursor: pointer;
    position: relative; }
  .custom_select:after {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
    font-size: 16px;
    content: "\f0dd";
    display: inline-block;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 2px; }

.md-button.md-default-theme.md-primary, .md-button.md-primary {
  color: #666666; }

.md-cancel-button {
  background-color: #e1e5ec;
  color: #666666; }

.md-confirm-button {
  background-color: #4fbfe0 !important;
  color: #666666; }

.login-page .login-header .md-headline {
  color: #1f232b; }

/*******************Google fonts************************************************************************/
@font-face {
  font-family: 'Fanwood Text';
  src: url("../../fonts/FanwoodText-Regular/FanwoodText-Regular.eot");
  src: local("?"), url("../../fonts/FanwoodText-Regular/FanwoodText-Regular.woff") format("woff"), url("../../fonts/FanwoodText-Regular/FanwoodText-Regular.ttf") format("truetype"), url("../../fonts/FanwoodText-Regular/FanwoodText-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Questrial';
  src: url("../../fonts/Questrial-Regular/Questrial-Regular.eot");
  src: local("?"), url("../../fonts/Questrial-Regular/Questrial-Regular.woff") format("woff"), url("../../fonts/Questrial-Regular/Questrial-Regular.ttf") format("truetype"), url("../../fonts/Questrial-Regular/Questrial-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Quicksand';
  src: url("../../fonts/Quicksand-Regular/Quicksand-Regular.eot");
  src: local("?"), url("../../fonts/Quicksand-Regular/Quicksand-Regular.woff") format("woff"), url("../../fonts/Quicksand-Regular/Quicksand-Regular.ttf") format("truetype"), url("../../fonts/Quicksand-Regular/Quicksand-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/Roboto-Regular/Roboto-Regular.eot");
  src: local("?"), url("../../fonts/Roboto-Regular/Roboto-Regular.woff") format("woff"), url("../../fonts/Roboto-Regular/Roboto-Regular.ttf") format("truetype"), url("../../fonts/Roboto-Regular/Roboto-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT Serif Caption';
  src: url("../../fonts/PT_Serif-Caption-Web-Regular/PT_Serif-Caption-Web-Regular.eot");
  src: local("?"), url("../../fonts/PT_Serif-Caption-Web-Regular/PT_Serif-Caption-Web-Regular.woff") format("woff"), url("../../fonts/PT_Serif-Caption-Web-Regular/PT_Serif-Caption-Web-Regular.ttf") format("truetype"), url("../../fonts/PT_Serif-Caption-Web-Regular/PT_Serif-Caption-Web-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hind Siliguri';
  src: url("../../fonts/HindSiliguri-Regular/HindSiliguri-Regular.eot");
  src: local("?"), url("../../fonts/HindSiliguri-Regular/HindSiliguri-Regular.woff") format("woff"), url("../../fonts/HindSiliguri-Regular/HindSiliguri-Regular.ttf") format("truetype"), url("../../fonts/HindSiliguri-Regular/HindSiliguri-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FjordOne';
  src: url("../../fonts/FjordOne-Regular/FjordOne-Regular.eot");
  src: local("?"), url("../../fonts/FjordOne-Regular/FjordOne-Regular.woff") format("woff"), url("../../fonts/FjordOne-Regular/FjordOne-Regular.ttf") format("truetype"), url("../../fonts/FjordOne-Regular/FjordOne-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Asar';
  src: url("../../fonts/Asar-Regular/Asar-Regular.eot");
  src: local("?"), url("../../fonts/Asar-Regular/Asar-Regular.woff") format("woff"), url("../../fonts/Asar-Regular/Asar-Regular.ttf") format("truetype"), url("../../fonts/Asar-Regular/Asar-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }
