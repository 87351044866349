/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
#loading-bar {
  pointer-events: all;
  z-index: 99999;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.1); }

.generate-banner-modal {
  margin: 5px auto; }

.color-input {
  width: 140px; }

.slider-title {
  color: #556370; }

.rzslider .rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #36c6d3;
  border-radius: 16px; }

.all-buttons {
  cursor: default; }

.invalid-input {
  color: #ff3939;
  margin-left: 30px; }

.headerShow {
  word-wrap: break-word; }
  .headerShow .btn-preview {
    padding: 0;
    background-color: #fcf8e3 !important;
    border-color: #fcf8e3 !important; }

.bannersize-detail .dimetions {
  width: 72px; }

.bannersize-detail .disabled {
  opacity: 0.6; }

.bannersize-detail li .form-control {
  display: inline;
  margin-left: 5px; }

.bannersize-detail i {
  font-size: 20px;
  margin-left: 15px;
  color: #7f7e51; }

.add-content-modal .nav-tabs > li {
  width: 50%; }
  .add-content-modal .nav-tabs > li a {
    font-weight: 600;
    color: #333333;
    font-size: 16px;
    border: 0 none;
    background-color: #F1F1F1; }
    .add-content-modal .nav-tabs > li a:hover {
      background-color: #F1F1F1; }

.add-content-modal .nav-tabs > li.active a {
  color: #ffffff;
  background-color: #373e4a;
  border: 0 none; }
  .add-content-modal .nav-tabs > li.active a:hover {
    color: #ffffff;
    background-color: #373e4a;
    border: 0 none; }

.add-content-modal .modal-header .project-title {
  font-size: 18px;
  color: #ffffff; }

.add-content-modal .modal-sm .close:hover, .add-content-modal .modal-sm .close {
  color: #ffffff; }

.create-template {
  padding-top: 20px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 15px; }
  .create-template .template-warp {
    position: relative; }
    .create-template .template-warp .template-left {
      /*width:280px;*/
      z-index: 1; }
    .create-template .template-warp .template-right {
      /*position: absolute;
			left:0px;
			padding-left:295px;
			width:100%;
			right:0;
			top:0;*/ }
  .create-template .pd-right-none {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 15px;
    /* @media (max-width: 1130px) {
		 width:197px;
	 }*/ }
  .create-template .pd-left-none {
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 0px; }
  .create-template .pd-top5 {
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
  .create-template .pd-top15 {
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
  .create-template .add-section {
    background-color: #2b303a;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    color: #ffffff;
    min-height: 550px; }
    .create-template .add-section button i {
      font-size: 16px;
      text-decoration: none; }
    .create-template .add-section input {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      text-decoration: none; }
    .create-template .add-section h5 {
      font-size: 18px;
      font-weight: 600;
      color: #fffda3; }
    .create-template .add-section label {
      font-size: 13px;
      font-weight: 600; }
    .create-template .add-section .head-main, .create-template .add-section .body-text, .create-template .add-section .link-txt, .create-template .add-section .img-add {
      text-align: center;
      position: relative;
      width: 46%;
      margin: 2%;
      float: left;
      border: #fffda3 solid 1px;
      display: table;
      /*@media (max-width: 1120px){
				width: 100%;
			}*/ }
      .create-template .add-section .head-main:hover, .create-template .add-section .body-text:hover, .create-template .add-section .link-txt:hover, .create-template .add-section .img-add:hover {
        background-color: #fffda3; }
        .create-template .add-section .head-main:hover span, .create-template .add-section .body-text:hover span, .create-template .add-section .link-txt:hover span, .create-template .add-section .img-add:hover span {
          color: #333333; }
    .create-template .add-section .head-main .draggableItems {
      min-height: 67px;
      height: 67px;
      display: table-cell;
      vertical-align: middle;
      cursor: pointer; }
    .create-template .add-section .head-main .hideitem, .create-template .add-section .body-text .hideitem, .create-template .add-section .link-txt .hideitem, .create-template .add-section .link-txt .cta-components, .create-template .add-section .body-components {
      min-height: 67px;
      height: 67px;
      display: table-cell;
      vertical-align: middle;
      cursor: move; }
    .create-template .add-section span {
      display: block;
      font-size: 13px;
      color: #ffffff; }
    .create-template .add-section .hidden-branding {
      display: none; }
    .create-template .add-section .hideitem {
      pointer-events: none;
      filter: alpha(opacity=30);
      -moz-opacity: 0.3;
      -khtml-opacity: 0.3;
      opacity: 0.3; }
    .create-template .add-section .branding .checkbox span {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 4px;
      border: none;
      margin-bottom: 0; }
  .create-template .add-section .element-box-cnt {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px; }
  .create-template .add-section .element.no-border {
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    border: 0; }
  .create-template .add-section .plus-icon {
    position: relative; }
    .create-template .add-section .plus-icon:hover:before {
      color: #333333; }
    .create-template .add-section .plus-icon:before {
      content: "\f067";
      font-family: FontAwesome;
      color: #666666;
      font-size: 16px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      cursor: pointer; }
  .create-template .add-section .img-icon {
    position: relative; }
    .create-template .add-section .img-icon:before {
      content: "\f1c5";
      font-family: FontAwesome;
      color: #666666;
      font-size: 32px;
      position: absolute;
      top: 28px;
      left: 40px;
      z-index: 99;
      cursor: pointer; }
  .create-template .add-section .element {
    font-size: 18px;
    line-height: 20px;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    margin-bottom: 0px; }
    @media (max-width: 991px) {
      .create-template .add-section .element {
        padding-top: 2px;
        padding-right: 2px;
        padding-bottom: 2px;
        padding-left: 2px; } }
    .create-template .add-section .element.img-add {
      text-align: center;
      position: relative;
      width: 46%;
      margin: 2%;
      float: left;
      border: #fffda3 solid 1px;
      cursor: move;
      display: table;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      /*@media (max-width: 1120px){
						width: 100%;
					}*/ }
      .create-template .add-section .element.img-add .img-box {
        text-align: center;
        position: relative;
        width: 100%;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        float: left;
        border: #fffda3 solid 1px;
        cursor: pointer;
        display: block;
        height: 74px;
        color: #373e4a; }
        .create-template .add-section .element.img-add .img-box img {
          height: auto !important;
          padding-top: 4px;
          margin: 0 auto; }
  .create-template .add-section .banner-style .element-box-cnt {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px; }
  .create-template .add-section .banner-style .element.no-border {
    float: left;
    width: 46%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    border: 0; }
    @media (max-width: 1390px) {
      .create-template .add-section .banner-style .element.no-border {
        width: 100%; }
        .create-template .add-section .banner-style .element.no-border label.add-space {
          padding-top: 0px;
          padding-right: 5px;
          padding-bottom: 0px;
          padding-left: 0px; } }
  .create-template .add-section .banner-style .btn-group button {
    border: 1px solid #fffda3;
    padding-top: 5px;
    padding-right: 2px;
    padding-bottom: 5px;
    padding-left: 2px;
    color: #ffffff; }
  .create-template .add-section .banner-style .btn-group input {
    border: 1px solid #fffda3;
    height: 32px;
    color: #ffffff;
    font-weight: normal;
    letter-spacing: 0px;
    width: 60px; }
    .create-template .add-section .banner-style .btn-group input:hover {
      background-color: transparent;
      color: #ffffff; }
  .create-template .add-section .input-group-addon {
    background: #fffda3; }
  .create-template .add-section .bor-divider {
    border-top: 2px solid #fffda3; }
  .create-template .editor-pagination {
    padding-top: 5px; }
    .create-template .editor-pagination ul {
      display: inline-block;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .create-template .editor-pagination ul li {
        display: inline; }
        .create-template .editor-pagination ul li button {
          float: left;
          padding-top: 5px;
          padding-right: 10px;
          padding-bottom: 5px;
          padding-left: 10px;
          margin-top: 0px;
          margin-right: 5px;
          margin-bottom: 0px;
          margin-left: 0px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          border-radius: 4px;
          line-height: 20px;
          font-weight: 600;
          text-decoration: none;
          background-color: #2b303a;
          color: #ffffff;
          border: 1px solid #2b303a;
          cursor: pointer;
          font-size: 14px;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; }
          .create-template .editor-pagination ul li button:hover {
            background-color: #fffda3;
            color: #333333;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out; }
          @media (max-width: 1060px) {
            .create-template .editor-pagination ul li button {
              padding-top: 6px;
              padding-right: 6px;
              padding-bottom: 6px;
              padding-left: 6px; } }
          .create-template .editor-pagination ul li button:disabled {
            opacity: .9; }
            .create-template .editor-pagination ul li button:disabled:hover {
              background-color: #fffda3;
              color: #333333;
              border: 1px solid #fffda3; }
          .create-template .editor-pagination ul li button:focus {
            background-color: #2b303a;
            color: #ffffff;
            border: 1px solid #2b303a; }
        .create-template .editor-pagination ul li:last-child button {
          margin-top: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
          margin-left: 0px; }
      .create-template .editor-pagination ul li.active button {
        background-color: #fffda3;
        color: #333333;
        border: 1px solid #fffda3;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
        .create-template .editor-pagination ul li.active button:focus {
          border: 1px solid #fffda3; }
  .create-template .layout-editor h5 {
    float: left;
    display: inline-block; }
  .create-template .layout-editor .add-btn-group {
    float: none;
    display: block;
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-left: 0px; }
  .create-template .layout-editor .close-section {
    float: right;
    display: inline-block; }
    .create-template .layout-editor .close-section a {
      height: 36px;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
      padding-top: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      border-radius: 2px;
      text-align: center;
      display: inline-block;
      position: relative;
      color: #ffffff;
      text-decoration: none; }
      .create-template .layout-editor .close-section a i {
        color: #ffffff; }
  .create-template .layout-editor .text-box {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    text-align: center; }

.panel-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-align: center; }

body .create-template {
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px; }
  body .create-template .shadow-input {
    display: inline-grid; }
    body .create-template .shadow-input input[type=number] {
      margin: 0 !important;
      width: 50px; }
    body .create-template .shadow-input .form-inline {
      display: inline-flex; }
  body .create-template .text-editor.formatting-icon {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    background-color: #2b303a;
    color: #ffffff; }
    body .create-template .text-editor.formatting-icon .custom_select:after {
      top: 0px; }
  body .create-template .text-editor ul.list-item {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    color: #ffffff;
    border: none; }
    body .create-template .text-editor ul.list-item li {
      margin-top: 5px;
      margin-right: 0px;
      margin-bottom: 5px;
      margin-left: 0px; }
      body .create-template .text-editor ul.list-item li label {
        font-weight: normal;
        font-size: 14px; }
      body .create-template .text-editor ul.list-item li select {
        border: 1px solid #333333;
        padding-top: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 10px;
        height: 34px;
        color: #333333; }
      body .create-template .text-editor ul.list-item li input[type="number"] {
        height: 34px;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 10px;
        color: #333333; }
        body .create-template .text-editor ul.list-item li input[type="number"]:hover {
          text-decoration: none; }
      body .create-template .text-editor ul.list-item li .btn {
        margin-top: 0px;
        margin-right: 1px;
        margin-bottom: 0px;
        margin-left: 0px;
        padding-top: 5px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
        width: 35px;
        background-color: #ffffff;
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        border-radius: 3px !important; }
        body .create-template .text-editor ul.list-item li .btn .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
          border-radius: 3px !important;
          width: 35px;
          border-top-right-radius: 3px !important;
          border-bottom-right-radius: 3px !important; }
      body .create-template .text-editor ul.list-item li .btn-default:hover {
        background-color: #ffffff; }
      body .create-template .text-editor ul.list-item li .btn-default:active {
        background-color: #ffffff; }
      body .create-template .text-editor ul.list-item li .e-active {
        background-color: #fffda3; }
        body .create-template .text-editor ul.list-item li .e-active:hover {
          background-color: #fffda3; }
    body .create-template .text-editor ul.list-item li.select-color select {
      padding-right: 25px; }
  body .create-template .text-editor .element_list {
    list-style-type: none;
    width: 250px;
    padding-left: 0;
    width: 100%; }

.editordivClone {
  display: none !important; }

.focused, .draggable {
  -webkit-box-shadow: #d47c7c 0px 0px 0px 2px;
  -moz-box-shadow: #d47c7c 0px 0px 0px 2px;
  box-shadow: #d47c7c 0px 0px 0px 2px;
  background: #e0e0e0; }

.jquery-div {
  min-width: 100px;
  min-height: 100px;
  padding: 5px; }

.fix-width-height {
  min-width: 100px;
  min-height: 100px; }

.create-template .add-section span.switch-right {
  color: #000000; }

.create-template .add-section p.dynamic-content-label {
  margin: 4px 4px; }

.preview-images {
  opacity: 0.0; }

.img-uploader {
  padding: 40px 0px 40px 0px; }

.imageupload-tab-pointer {
  cursor: pointer; }

.img-uploader-progress {
  margin-top: 5px; }

.colorpicker {
  z-index: 15; }

html {
  height: inherit;
  background: #ffffff; }

.popover {
  background-color: #fffda3 !important; }

.popover-title, .popover-content {
  background-color: #fffda3 !important;
  color: #2b303a !important; }

.popover-title {
  border-bottom: 1px solid #dddddd !important; }

.popover.left > .arrow:after {
  border-left-color: #fffda3; }

.popover.right > .arrow:after {
  border-right-color: #fffda3; }

.popover.top > .arrow:after {
  border-top-color: #fffda3; }

.popover.bottom > .arrow:after {
  border-top-bottom: #fffda3; }

md-dialog {
  position: absolute;
  top: 10%; }

.file-size {
  color: white;
  padding-top: 16px; }

.dwn-wrapper {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-top: 50px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  margin: 0;
  display: block; }
  .dwn-wrapper h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600; }

.baneersModal {
  padding: 0; }

.dwn-wrapper ul.generateBanner {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  list-style: none; }
  .dwn-wrapper ul.generateBanner li {
    color: #ffffff;
    display: block;
    position: relative;
    float: left;
    width: 50%; }
    .dwn-wrapper ul.generateBanner li input[type=radio] {
      position: absolute;
      visibility: hidden; }
    .dwn-wrapper ul.generateBanner li label {
      display: block;
      position: relative;
      font-weight: 300;
      font-size: 16px;
      font-weight: 500;
      margin: 10px auto;
      height: 30px;
      z-index: 9;
      cursor: pointer;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 25px;
      padding-left: 35px;
      -webkit-transition: all 0.25s linear;
      -moz-transition: all 0.25s linear;
      -o-transition: all 0.25s linear;
      transition: all 0.25s linear; }
    .dwn-wrapper ul.generateBanner li:hover {
      color: #ffffff; }
    .dwn-wrapper ul.generateBanner li:hover .check {
      border: 2px solid #ffffff; }
  .dwn-wrapper ul.generateBanner li .check {
    display: block;
    position: absolute;
    border: 2px solid #ffffff;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 10px;
    left: 0px;
    right: 10px;
    z-index: 5;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear; }
    .dwn-wrapper ul.generateBanner li .check:before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 11px;
      width: 11px;
      top: 5px;
      left: 5px;
      margin: auto;
      -webkit-transition: all 0.25s linear;
      -moz-transition: all 0.25s linear;
      -o-transition: all 0.25s linear;
      transition: all 0.25s linear; }
  .dwn-wrapper ul.generateBanner .md-default-theme:not(.md-checked) .md-icon, .dwn-wrapper ul.generateBanner .md-checkbox:not(.md-checked) .md-icon {
    border-color: #ffffff; }
  .dwn-wrapper ul.generateBanner .md-ink-ripple {
    color: #ffffff !important; }

.dwn-wrapper .compress span {
  color: #ffffff;
  font-size: 14px; }

.dwn-wrapper .btn {
  opacity: 1; }

input[type=radio]:checked ~ .check {
  border: 2px solid #ffffff; }

input[type=radio]:checked ~ .check::before {
  background: #ffffff; }

input[type=radio]:checked ~ label {
  color: #ffffff; }

md-checkbox.md-default-theme:not(.md-checked) .md-icon, md-checkbox:not(.md-checked) .md-icon {
  border-color: #ffffff !important; }

md-checkbox.md-checked .md-icon {
  border-color: #ffffff !important;
  background-color: transparent !important; }

.md-label {
  color: #ffffff !important; }
