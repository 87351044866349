/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
/*Dashboard*/
.upgrade-btn {
  background: #fffda3;
  padding: 10px 15px !important;
  box-shadow: 0 3px 2px -1px #969385;
  margin: 10px;
  float: right;
  cursor: pointer;
  color: #2b303a; }

.upgrade-btn:hover {
  background: #fece00;
  text-decoration: none;
  color: #2b303a; }

.projectlist-top {
  background: #f1f1f1; }
  .projectlist-top .clearfix {
    float: left; }

.content h1.project-list {
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px; }

.content h4 {
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 0x; }

.content .edit-project-list {
  outline: none; }

.content .project-entries {
  display: inline-block;
  width: 100px; }

.content .entries-lable {
  color: #9a9a9a; }

.content .edit-project-list .box {
  position: relative;
  padding: 0;
  background: #fece00;
  margin-bottom: 20px; }
  .content .edit-project-list .box .template-box {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 15px; }
    .content .edit-project-list .box .template-box h5 {
      text-align: left;
      color: #333333;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 1.6;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0x;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0x;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 92%; }
      .content .edit-project-list .box .template-box h5 i {
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
        margin-left: 0x; }
  .content .edit-project-list .box:hover {
    background: #f4c600; }
  .content .edit-project-list .box .more-icon {
    position: absolute;
    right: 10px;
    top: 10px; }
    .content .edit-project-list .box .more-icon .btn {
      background: rgba(0, 0, 0, 0.4); }
      .content .edit-project-list .box .more-icon .btn i {
        color: #ffffff; }
