/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
/*Login page*/
.btn-primary[disabled]:hover {
  background: #54bfdf;
  border-color: #54bfdf; }

.login-page {
  background-color: #14171f;
  background-image: -webkit-gradient(left top, left bottom, color-stop(0%, #14171f), color-stop(100%, #2b303a));
  background-image: -webkit-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -moz-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -ms-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -o-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: linear-gradient(to bottom, #14171f 0%, #2b303a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr='#14171f', end-colourStr='#2b303a',GradientType=0 );
  min-height: 100%;
  position: absolute;
  width: 100%;
  height: auto; }

.login-page .login-header {
  position: relative;
  padding-top: 50px;
  padding-right: 0px;
  padding-bottom: 50px;
  padding-left: 0px; }

.login-page .login-header, .input-group-addon {
  color: #ffffff;
  background-color: white;
  background-image: -moz-linear-gradient(top, white 0%, #f6f6f6 47%, #fffda3 100%);
  background-image: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(47%, #f6f6f6), color-stop(100%, #fffda3));
  background-image: -webkit-linear-gradient(top, white 0%, #f6f6f6 47%, #fffda3 100%);
  background-image: -o-linear-gradient(top, white 0%, #f6f6f6 47%, #fffda3 100%);
  background-image: -ms-linear-gradient(top, white 0%, #f6f6f6 47%, #fffda3 100%);
  background-image: linear-gradient(to bottom, white 0%, #f6f6f6 47%, #fffda3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='white', endColorstr='#fffda3', GradientType=0 ); }

.login-page .login-header.login-caret:after {
  border-color: #fffda3 transparent transparent;
  border-style: solid;
  border-width: 13px 12.5px 0;
  bottom: -13px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -12.5px;
  position: absolute;
  transition: all 550ms ease-in-out;
  width: 0;
  z-index: 1; }

.login-page .md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.login-page .login-content {
  position: relative;
  width: 320px;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  -webkit-transition: all 550ms ease-in-out;
  -moz-transition: all 550ms ease-in-out;
  -o-transition: all 550ms ease-in-out;
  transition: all 550ms ease-in-out; }

.login-page .login-form {
  zoom: 1;
  top: 0px;
  opacity: 1;
  -webkit-transition: all 550ms ease-in-out;
  -moz-transition: all 550ms ease-in-out;
  -o-transition: all 550ms ease-in-out;
  transition: all 550ms ease-in-out;
  position: relative;
  padding-top: 60px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px; }
  .login-page .login-form .form-group .input-group {
    background: #373e4a;
    border: 1px solid #373e4a;
    padding-top: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    padding-left: 7px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box; }
    .login-page .login-form .form-group .input-group .input-group-addon {
      background: transparent;
      border: none; }
    .login-page .login-form .form-group .input-group .form-control {
      border: 0 !important; }
      .login-page .login-form .form-group .input-group .form-control:focus {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important; }
      .login-page .login-form .form-group .input-group .form-control:last-child {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px; }
  .login-page .login-form .form-group .btn-login {
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #f56954;
    border: 1px solid #454a54;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left: 20px; }
    .login-page .login-form .form-group .btn-login:hover {
      background-color: #373e4a;
      color: #ffffff;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
    .login-page .login-form .form-group .btn-login i {
      float: right; }
  .login-page .login-form .login-bottom-links {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px; }
    .login-page .login-form .login-bottom-links a {
      font-size: 14px;
      display: inline-block;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 10px;
      margin-left: 0px; }
      .login-page .login-form .login-bottom-links a:hover {
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
