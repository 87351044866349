.project-entries {
  display: inline-block;
  width: 100px; }

.entries-lable {
  color: #9a9a9a; }

.ziplist_row {
  background: #e2e1bb; }
  .ziplist_row td {
    border-top: 0 !important; }

.traffic-source-size {
  font-size: 14px;
  margin-left: 7px;
  font-weight: 500;
  vertical-align: middle;
  color: #b7b7b7; }

.traffic-source-size span {
  font-weight: 600; }

.download_projects__loading {
  display: block;
  margin: 0 auto;
  animation: rotation_loading rotation 2s infinite linear;
  -webkit-animation: rotation_loading 2s infinite linear; }

@keyframes rotation_loading {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

.ziplist_container {
  background: #f1f0d5; }
  .ziplist_container .btn {
    background: #877325;
    color: #ffffff;
    margin: 10px;
    padding: 2px 10px; }

.radio-button-container {
  display: flex;
  align-items: center; }

.radio-button-container div {
  margin-left: 30px;
  align-self: flex-end; }

.radio-button-container input[type="radio"] {
  -ms-transform: scale(2);
  /* IE 9 */
  -webkit-transform: scale(2);
  /* Chrome, Safari, Opera */
  transform: scale(2); }

.modal-body-custom {
  padding-left: 40px;
  color: white; }

.modal-body {
  color: white; }

.modal-body, .modal-footer {
  background-color: black; }

.option-type-container {
  margin-bottom: 2rem; }

.modes-container {
  padding-left: 3rem; }
