.header-container {
  padding: 20px;
  padding-left: 30px; }

.header-container h1 {
  font-size: 25px; }

.tasks-table td {
  vertical-align: middle !important; }

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  transform: scale(1.5); }

.icon-background {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center; }

.color-pending {
  color: #FFC107; }

.color-in-progress {
  color: #17A2B8; }

.color-completed {
  color: #28A745; }

.color-failed {
  color: #DC3545; }

.color-stopped {
  color: #6C757D; }
